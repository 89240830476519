import * as bubbleChartAntiCollision from './bubbleChartAntiCollision';
import {
  ChartDimensions,
  Margin,
  Rect,
  getRectCenter,
  inflate,
  intersects,
  shiftInto,
} from '@ardoq/graph';
import type { Point } from '@ardoq/math';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { DataPoint } from './dataPoint';
import { DataPointLabel } from './dataPointLabel';
import type {
  BubbleChartBubbleModel,
  BubbleChartDataPointModel,
  BubbleChartLabelData,
  BubbleChartScalers,
  BubbleLayoutInfo,
} from './types';
import { DISPLAYED_LABELS_LIMIT } from './consts';
import { MeasureStyledText } from '@ardoq/dom-utils';
import { uniqBy } from 'lodash';

const measureText = new MeasureStyledText().getTextWidth;

const getLabelRect = (label: BubbleChartBubbleModel) => {
  const width = measureText({
    text: label.label,
    fontSize: `${label.fontSize}px`,
  });
  return new Rect(
    label.x - width / 2,
    label.y - label.fontSize / 2,
    width,
    label.fontSize
  );
};
const positionLabels = (
  dimensions: ChartDimensions,
  dataPoints: BubbleChartBubbleModel[]
) => {
  const items = [...dataPoints].map<BubbleLayoutInfo>((bubble, index) => ({
    cid: bubble.cid,
    center: [bubble.x, bubble.y],
    radius: bubble.radius,
    labelRect:
      index < DISPLAYED_LABELS_LIMIT
        ? getLabelRect(dataPoints[index])
        : Rect.IMPOSSIBLE,
    index,
    fontSize: index < DISPLAYED_LABELS_LIMIT ? dataPoints[index].fontSize : NaN,
  }));

  const bubblesToDecollide = items.slice(0, DISPLAYED_LABELS_LIMIT);
  const remainingBubbles = items.slice(DISPLAYED_LABELS_LIMIT, items.length);

  const decollidedBubbles = bubbleChartAntiCollision.decollide({
    input: bubblesToDecollide,
    center: [dimensions.chart.width / 2, dimensions.chart.height / 2],
    bounds: new Rect(0, 0, dimensions.chart.width, dimensions.chart.height),
  });
  return new Map(
    [...decollidedBubbles, ...remainingBubbles].map(bubble => [
      bubble.cid,
      bubble,
    ])
  );
};

const getBoundingBox = ({ x, y, radius }: BubbleChartBubbleModel) =>
  new Rect(x - radius, y - radius, 2 * radius, 2 * radius);

const makeScaler =
  (scalers: BubbleChartScalers) =>
  (
    data: BubbleChartDataPointModel,
    sortValue: number
  ): BubbleChartBubbleModel => ({
    ...data,
    x: scalers.x(data.x),
    y: scalers.y(data.y),
    radius: scalers.radius(data.radius),
    fontSize: scalers.fontSize(data.radius),
    fontOpacity: scalers.fontOpacity(sortValue),
    isBlurred: false,
  });

type GetLabelsForHighlightedItemArgs = {
  highlightedItemId?: string;
  arrangedDataPoints: BubbleChartBubbleModel[];
  highlightLabelPosition: Point | null;
  chartBounds: Rect;
};
const getLabelsForHighlightedItem = ({
  highlightedItemId,
  arrangedDataPoints,
  highlightLabelPosition,
  chartBounds,
}: GetLabelsForHighlightedItemArgs): BubbleChartLabelData[] | undefined => {
  if (!highlightedItemId) {
    return;
  }
  const highlightDataPoint = arrangedDataPoints.find(
    dataPoint => dataPoint.cid === highlightedItemId
  );
  if (!highlightDataPoint) {
    return;
  }

  /**
   *  When the item is hovered over and/or focused, we need to display 2 labels for the same item and make one of them transparent.
   * If we just move one label, the highlight area stops to point to the highlighted item and the chart updates hover and/or focus state.
   */

  const [highlightedLabelX, highlightedLabelY] = highlightLabelPosition ?? [];

  const decollidedHighlightedLabel =
    highlightedLabelX !== undefined &&
    highlightedLabelY !== undefined &&
    !(
      highlightedLabelY === highlightDataPoint.y &&
      highlightedLabelX === highlightDataPoint.x
    )
      ? {
          ...highlightDataPoint,
          x: highlightedLabelX,
          y: highlightedLabelY,
          fontOpacity: 0,
          key: highlightDataPoint.uniqueId ?? highlightDataPoint.cid,
        }
      : null;

  if (decollidedHighlightedLabel) {
    const [newCenterX, newCenterY] = getRectCenter(
      shiftInto(
        getLabelRect(decollidedHighlightedLabel),
        inflate(chartBounds, -bubbleChartAntiCollision.MIN_MARGIN)
      )
    );
    decollidedHighlightedLabel.x = newCenterX;
    decollidedHighlightedLabel.y = newCenterY;
  }

  const labelOnHighlightedDataPoint = {
    ...highlightDataPoint,
    fontOpacity: 1,
    key: `${highlightDataPoint.uniqueId ?? highlightDataPoint.cid}_2`,
  };

  return [labelOnHighlightedDataPoint, decollidedHighlightedLabel].filter(
    ExcludeFalsy
  );
};

type GetDecollidedDataPointLabelsArgs = {
  showLabels: boolean;
  arrangedDataPoints: BubbleChartBubbleModel[];
  dimensions: ChartDimensions;
};
const getDecollidedDataPointLabels = ({
  showLabels,
  arrangedDataPoints,
  dimensions,
}: GetDecollidedDataPointLabelsArgs): BubbleChartLabelData[] | undefined => {
  if (!showLabels) {
    return;
  }

  const layoutData = positionLabels(dimensions, arrangedDataPoints);

  return arrangedDataPoints
    .slice(0, DISPLAYED_LABELS_LIMIT)
    .map(dataPoint => {
      const itemLayout = layoutData && layoutData.get(dataPoint.cid);
      const [labelCenterX, labelCenterY] = itemLayout
        ? getRectCenter(itemLayout.labelRect)
        : [dataPoint.x, dataPoint.y];
      return { ...dataPoint, ...{ x: labelCenterX, y: labelCenterY } };
    })
    .filter(label => !isNaN(label.x));
};

interface ChartAreaArgs {
  dimensions: ChartDimensions;
  margin: Margin;
  data: BubbleChartDataPointModel[];
  scalers: BubbleChartScalers;
  showLabels: boolean;
  hoverId: string;
  focusId: string;
  hoverLabelPosition: Point | null;
  focusLabelPosition: Point | null;
  clipPathUrl: string;
  showBubblesWithZeroValue: boolean;
}
export const ChartArea = (args: ChartAreaArgs) => {
  const {
    dimensions,
    margin,
    data,
    scalers,
    showLabels,
    hoverId,
    focusId,
    hoverLabelPosition,
    focusLabelPosition,
    clipPathUrl,
    showBubblesWithZeroValue,
  } = args;
  const scaler = makeScaler(scalers);
  const chartBounds = new Rect(
    0,
    0,
    dimensions.chart.width,
    dimensions.chart.height
  );

  const arrangedDataPoints = data
    .sort((a, b) => b.radius - a.radius)
    .map((dataPoint, index) => ({
      ...dataPoint,
      ...scaler(dataPoint, Math.min(100, data.length) - index - 1),
      isBlurred:
        (!!hoverId || !!focusId) &&
        dataPoint.cid !== hoverId &&
        dataPoint.cid !== focusId,
    }))
    .filter(scaledDataPoint =>
      intersects(chartBounds, getBoundingBox(scaledDataPoint))
    );

  const highlightedLabels = uniqBy(
    [
      ...(getLabelsForHighlightedItem({
        highlightedItemId: hoverId,
        arrangedDataPoints,
        highlightLabelPosition: hoverLabelPosition,
        chartBounds,
      }) || []),
      ...(getLabelsForHighlightedItem({
        highlightedItemId: focusId,
        arrangedDataPoints,
        highlightLabelPosition: focusLabelPosition,
        chartBounds,
      }) || []),
    ],
    'cid'
  );

  const arrangedLabels =
    highlightedLabels.length > 0
      ? highlightedLabels
      : getDecollidedDataPointLabels({
          showLabels,
          arrangedDataPoints,
          dimensions,
        });

  return (
    <g
      clipPath={`url(#${clipPathUrl})`}
      transform={`translate(${margin.left}, ${margin.top})`}
    >
      {arrangedDataPoints.map(
        scaledDataPoint =>
          (showBubblesWithZeroValue || scaledDataPoint.radius) && (
            <DataPoint
              key={scaledDataPoint.uniqueId ?? scaledDataPoint.cid}
              data={scaledDataPoint}
            />
          )
      )}
      {showLabels &&
        arrangedLabels!.map(d => (
          <DataPointLabel key={d.key ?? d.uniqueId ?? d.cid} data={d} />
        ))}
    </g>
  );
};
