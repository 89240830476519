import { Vector } from '@ardoq/graph';
import {
  BlocksViewGraph,
  BlocksViewLink,
  BlocksViewNode,
  BlocksViewVisualState,
  Controller,
  InteractionMode,
  Interactions,
} from '../types';
import { isGroup } from '../viewModel$/util';
import { hitTestExpander } from '../visual/node';
import {
  hitTestGraphLinks,
  hitTestGraphNodes,
  renderGraph,
} from '../visual/visual';
import { hitTestLink } from '../visual/link';

import {
  rectCenter,
  rectContains,
  rectHeight,
  rectWidth,
} from '../misc/geometry';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';
import { dispatchAction } from '@ardoq/rxbeach';
import { clamp } from 'lodash';
import { BLOCKS_VIEW_FIT_PADDING } from '../consts';
import { IconName } from '@ardoq/icons';
import { setContextMenuState } from 'contextMenus/contextMenuState$';
import { SettingsConfig, SettingsType } from '@ardoq/view-settings';
import { isPresentationMode } from 'appConfig';

import { Linked, getLinked } from '../misc/graph';
import {
  linkContextMenuOptions,
  nodeContextMenuOptions,
  rootContextMenuOptions,
} from './contextMenuOptions';
import type { DropdownItem } from '@ardoq/dropdown-menu';

export const createViewInteractions = (
  controller: Controller,
  graph: BlocksViewGraph
): Interactions => {
  enum InteractionState {
    idle = 0,
    armed = 1,
    pan = 2,
    createReference = 3,
  }

  let pointerState: InteractionState = InteractionState.idle;

  let pointerId: number | null = null; // last known pointer id
  let pointerDownDevice: Vector = [NaN, NaN]; // last known pointer down position
  let pointerDownWorld: Vector = [NaN, NaN]; // last known pointer down position
  let pointerDevice: Vector = [NaN, NaN]; // last known pointer position
  let pointerWorld: Vector = [NaN, NaN]; // last known pointer position
  let pointerNode: BlocksViewNode | null = null; // node under the pointer
  let pointerLink: BlocksViewLink | null = null; // link under the pointer
  let linked: Linked | null = null; // nodes and links related in some way to the pointer node

  const canvas = controller.canvas;
  const projection = controller.projection;
  const selection = controller.selection;
  const ctx = canvas.getContext('2d', { alpha: false })!;

  const attach = () => {
    const canvas = controller.canvas;

    // clear the current selection
    // populate it from the graph

    canvas.addEventListener('pointerdown', onPointerDown);
    canvas.addEventListener('pointermove', onPointerMove);
    canvas.addEventListener('pointerup', onPointerUp);
    canvas.addEventListener('pointerleave', onPointerLeave);
    canvas.addEventListener('lostpointercapture', onLostPointerCapture);
    canvas.addEventListener('wheel', onWheel);
    canvas.addEventListener('keydown', onKeyDown);
    canvas.addEventListener('dblclick', onDoubleClick);
    canvas.addEventListener('contextmenu', onContextMenu);
    canvas.addEventListener('focusin', onFocus);
    canvas.addEventListener('focusout', onFocus);
  };

  const detach = () => {
    const canvas = controller.canvas;

    canvas.removeEventListener('pointerdown', onPointerDown);
    canvas.removeEventListener('pointermove', onPointerMove);
    canvas.removeEventListener('pointerup', onPointerUp);
    canvas.removeEventListener('pointerleave', onPointerLeave);
    canvas.removeEventListener('lostpointercapture', onLostPointerCapture);
    canvas.removeEventListener('wheel', onWheel);
    canvas.removeEventListener('keydown', onKeyDown);
    canvas.removeEventListener('dblclick', onDoubleClick);
    canvas.removeEventListener('contextmenu', onContextMenu);
    canvas.removeEventListener('focusin', onFocus);
    canvas.removeEventListener('focusout', onFocus);
  };

  const renderCanvas = () => {
    ctx.setTransform(projection.matrix());
    const done = renderGraph(
      graph,
      projection.window(),
      linked !== null ? 'lowlight' : 'normal',
      null,
      ctx
    );

    return done;
  };

  const cursor = () => {
    if (
      pointerState === InteractionState.idle &&
      (pointerNode || pointerLink)
    ) {
      return 'pointer'; // pointy finger
    }

    if (pointerState === InteractionState.armed) {
      if (pointerNode || pointerLink) {
        return 'pointer'; // pointy finger
      }

      return 'grabbing'; // grabby hand (fairly sure that this won't happen)
    }

    if (pointerState === InteractionState.pan) {
      return 'grabbing';
    }

    return 'grab';
  };

  const onPointerDown = (e: PointerEvent) => {
    if (pointerState === InteractionState.idle && e.button === 0) {
      pointerDevice = [e.offsetX, e.offsetY];
      pointerWorld = projection.toWorld(pointerDevice);

      if (pointerState === InteractionState.idle) {
        canvas.setPointerCapture(e.pointerId); // grab the pointer
        pointerId = canvas.hasPointerCapture(e.pointerId) ? e.pointerId : null;
      }

      if (pointerId) {
        pointerState = InteractionState.armed;

        pointerDownDevice = pointerDevice;
        pointerDownWorld = pointerWorld;
      }
    }

    controller.cancelTooltip();
    canvas.style.cursor = cursor();
  };

  const onPointerMove = (e: PointerEvent) => {
    pointerDevice = [e.offsetX, e.offsetY];
    pointerWorld = projection.toWorld(pointerDevice);

    if (pointerState === InteractionState.armed && e.pointerId === pointerId) {
      const distance = Math.hypot(
        pointerDevice[0] - pointerDownDevice[0],
        pointerDevice[1] - pointerDownDevice[1]
      );

      // pointer down then move more than five pels turns this into a drag, which means "pan" for a viewer
      pointerState = distance > 5 ? InteractionState.pan : pointerState;
    }

    if (pointerState === InteractionState.pan && e.pointerId === pointerId) {
      projection.adjustToPoint(pointerDownWorld, pointerDevice);
      pointerWorld = projection.toWorld(pointerDevice);
      controller.renderCanvas();
    }

    if (
      pointerState === InteractionState.createReference &&
      e.pointerId === pointerId
    ) {
      /*
      let newPointerNode = hitTestGraphNodes(
        pointerNode && rectContains(pointerNode.bounds, pointerWorld)
          ? pointerNode
          : graph.root,
        pointerWorld,
        ctx
      );

      if (newPointerNode) {
        if (newPointerNode != oldPointerNode) {
          // create a router
          // route "spare reference" from pointerNode to newPointerNode
        }
      } else {
        // configure "spare reference" as being a straight line from pointerNode to newPointerNode
      }
      */
    }

    if (pointerState === InteractionState.idle) {
      const slop = 4 / projection.resolution();
      const newPointerLink =
        pointerLink && hitTestLink(pointerLink, pointerWorld, slop)
          ? pointerLink
          : hitTestGraphLinks(graph, pointerWorld, ctx);

      let newPointerNode = newPointerLink
        ? null
        : pointerNode && rectContains(pointerNode.bounds, pointerWorld)
          ? hitTestGraphNodes(pointerNode, pointerWorld)
          : hitTestGraphNodes(graph.root, pointerWorld);

      if (newPointerNode && !newPointerNode.parent) {
        /* root node doesn't count as a node */
        newPointerNode = null;
      }

      const oldItem = pointerLink ?? pointerNode;
      const newItem = newPointerLink ?? newPointerNode;

      pointerNode = newPointerNode;

      if (pointerLink && pointerLink !== newPointerLink) {
        pointerLink.visualState &= ~BlocksViewVisualState.Hover;
      }
      pointerLink = newPointerLink;

      if (newItem !== oldItem) {
        /* "hover" effect */

        if (linked !== null) {
          for (const link of linked.links) {
            link.visualState &= ~BlocksViewVisualState.Highlight;
          }

          for (const node of linked.nodes) {
            node.visualState &= ~BlocksViewVisualState.Highlight;
          }

          linked = null;
        }

        if (pointerLink) {
          pointerLink.visualState |= BlocksViewVisualState.Hover;
        }

        if (pointerNode && !pointerNode.open) {
          linked = getLinked(pointerNode);
        }

        if (linked) {
          for (const link of linked.links) {
            link.visualState |= BlocksViewVisualState.Highlight;
          }

          for (const node of linked.nodes) {
            node.visualState |= BlocksViewVisualState.Highlight;
          }
        }
      }

      if (newItem !== oldItem) {
        controller.cancelTooltip();

        if (pointerLink) {
          controller.showLinkTooltip(pointerLink, pointerWorld);
        }

        if (pointerNode) {
          controller.showNodeTooltip(pointerNode, pointerWorld);
        }

        /* mouse move has caused the hover item to change; heaven knows what that does visually */
        controller.renderCanvas();
      }
    }

    canvas.style.cursor = cursor();
  };

  const onPointerUp = (e: PointerEvent) => {
    if (e.button !== 0 || !pointerId || e.pointerId !== pointerId) {
      return;
    }

    const overExpander =
      pointerNode !== null && hitTestExpander(pointerNode, pointerDownWorld);

    if (pointerState === InteractionState.armed && !overExpander) {
      /* handle selection */
      pointerState = InteractionState.idle;
      controller.renderCanvas();
    }

    if (
      pointerState === InteractionState.armed &&
      pointerNode &&
      overExpander
    ) {
      controller.setOpen(pointerNode, !pointerNode.open);
    }

    if (pointerState === InteractionState.createReference) {
      /*
      if (pointerNode) {
        // done creating a reference
      } else {
        // not creating a reference after all
      }
      */
    }

    if (pointerState === InteractionState.pan && e.pointerId === pointerId) {
      // nothing to do
    }

    pointerState = InteractionState.idle;
    canvas.releasePointerCapture(pointerId); // release the capture
    canvas.style.cursor = cursor();
  };

  // this is completely generic and could be moved out
  const onPointerLeave = (_e: PointerEvent) => {
    controller.cancelTooltip();
    canvas.style.cursor = cursor();
  };

  // this is almost completely generic and could be moved out
  const onLostPointerCapture = (_e: PointerEvent) => {
    switch (pointerState) {
      case InteractionState.idle:
        break;

      case InteractionState.armed:
        // disarm
        break;

      case InteractionState.pan:
        // put view back to original position
        break;
    }

    pointerState = InteractionState.idle;
    canvas.style.cursor = cursor();
  };

  // this is completely generic and could be moved out
  const onKeyDown = (e: KeyboardEvent) => {
    const selection = controller.selection;

    const rc = projection.window();
    const speed = e.shiftKey ? 3 : 1;
    let handled = true;

    switch (e.key) {
      case 'PageUp':
        controller.zoomTo(rectCenter(rc), 1.1 * speed);
        break;

      case 'PageDown':
        controller.zoomTo(rectCenter(rc), 0.9 / speed);
        break;

      case 'ArrowLeft': // possibly an action of some kind
        projection.translateBy([speed * 0.05 * rectWidth(rc), 0]);
        break;

      case 'ArrowUp': // possibly an action of some kind
        projection.translateBy([0, speed * 0.05 * rectHeight(rc)]);
        break;

      case 'ArrowRight': // possibly an action of some kind
        projection.translateBy([-speed * 0.05 * rectWidth(rc), 0]);
        break;

      case 'ArrowDown': // possibly an action of some kind
        projection.translateBy([0, -speed * 0.05 * rectHeight(rc)]);
        break;

      case 'Enter': // possibly an action of some kind
        if (e.shiftKey && !selection.empty()) {
          const fit = selection.bounds();

          projection.adjustToRect(fit, BLOCKS_VIEW_FIT_PADDING);
        } else {
          projection.adjustToRect(graph.root.bounds!, BLOCKS_VIEW_FIT_PADDING);
        }
        break;

      case ' ': // possibly an action of some kind
        if (pointerNode && isGroup(pointerNode)) {
          controller.setOpen(pointerNode, !pointerNode.open);
        }
        break;

      case 'Escape':
        if (pointerId && canvas.hasPointerCapture(pointerId)) {
          canvas.releasePointerCapture(pointerId);
        }
        break;

      case 'Tab':
        // move selection
        break;

      default:
        handled = false;
        break;
    }

    if (handled) {
      pointerWorld = projection.toWorld(pointerDevice); // recalculate the world position just in case the projection got changed
      controller.cancelTooltip();
      controller.renderCanvas(); // some of the events will already have done this, but rendering twice is a free operation
      e.stopPropagation();
    }
  };

  // this is completely generic and could be moved out
  const onWheel = (e: WheelEvent) => {
    const deltaY = clamp(e.deltaY, -800, 800) / 1000;

    e.stopPropagation();
    controller.cancelTooltip();
    controller.zoomTo(pointerWorld, 1 - deltaY);
  };

  // this is almost completely generic and could be moved out
  const onDoubleClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (pointerNode && pointerNode.modelId) {
      dispatchAction(openDetailsDrawer([pointerNode.modelId!]));
    }
  };

  // this is completely generic and could be moved out
  const onContextMenu = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const options = contextMenuOptions();

    if (pointerState === InteractionState.idle) {
      if (options.length !== 0) {
        controller.cancelTooltip();

        dispatchAction(
          setContextMenuState({
            items: options,
            position: { left: e.clientX, top: e.clientY },
          })
        );
      }
    }
  };

  const onFocus = (_e: FocusEvent) => {
    // view has gained or lost focus
    // this should be visible on the screen in some way
    controller.renderCanvas();
  };

  const contextMenuOptions = (): DropdownItem[] => {
    if (pointerState !== InteractionState.idle) {
      return [];
    }

    return pointerLink
      ? linkContextMenuOptions(controller!, pointerLink)
      : pointerNode
        ? nodeContextMenuOptions(controller!, pointerNode, selection.nodes())
        : rootContextMenuOptions(controller!);
  };

  const viewActionsConfigs = (): SettingsConfig[] => {
    const configs: SettingsConfig[] = [];
    const hasGroups = true; // are there any groups in the set of nodes

    const selection = controller.selection;
    // view controller should have an edit button if the fucking thing isn't in presentation mode

    if (!isPresentationMode()) {
      configs.push({
        id: 'id_editmode',
        type: SettingsType.TOGGLE,
        label: 'Edit mode',
        onClick: () => controller.setInteractionMode(InteractionMode.Edit),
        iconName: IconName.EDIT,
        isDisabled: false,
      });
    }

    configs.push({
      id: 'id_collapse_all',
      type: SettingsType.BUTTON,
      label: 'Collapse all',
      onClick: controller.collapseAll,
      iconName: IconName.COLLAPSE_ALL,
      isDisabled: !hasGroups,
    });
    configs.push({
      id: 'id_expand_all',
      type: SettingsType.BUTTON,
      label: 'Expand all',
      onClick: controller.expandAll,
      iconName: IconName.EXPAND_ALL,
      isDisabled: !hasGroups,
    });

    configs.push({ id: 'id_divider3', type: SettingsType.DIVIDER });

    configs.push({
      id: 'id_zoom_in',
      type: SettingsType.BUTTON,
      label: 'Zoom in',
      onClick: controller.zoomIn,
      iconName: IconName.ZOOM_IN,
      isDisabled: false,
    });
    configs.push({
      id: 'id_zoom_out',
      type: SettingsType.BUTTON,
      label: 'Zoom out',
      onClick: controller.zoomOut,
      iconName: IconName.ZOOM_OUT,
    });
    configs.push({
      id: 'id_zoom_to_fit',
      type: SettingsType.BUTTON,
      label: 'Fit',
      onClick: () => {
        controller.fit(
          selection.empty() ? graph.root.bounds! : selection.bounds()
        );
      },
      iconName: IconName.ZOOM_TO_FIT,
    });

    return configs;
  };

  return {
    attach,
    detach: detach,
    renderCanvas,
    contextMenuOptions: contextMenuOptions,
    viewActionsConfigs: viewActionsConfigs,
  };
};
