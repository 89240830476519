import { clamp } from 'lodash';
import { dispatchAction } from '@ardoq/rxbeach';
import type { Rectangle, Vector } from '@ardoq/graph';
import { type DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import { workspaceInterface } from '@ardoq/workspace-interface';
import {
  type DropdownConfig,
  type SettingsConfig,
  SettingsType,
} from '@ardoq/view-settings';
import { IconName } from '@ardoq/icons';
import {
  BlocksViewGraph,
  BlocksViewLink,
  BlocksViewNode,
  BlocksViewVisualState,
  Controller,
  DragOperation,
  Interactions,
} from '../types';
import { setContextMenuState } from 'contextMenus/contextMenuState$';
import { isAscendancyOpen, isGroup } from '../viewModel$/util';
import {
  rectCenter,
  rectEnclose,
  rectHeight,
  rectIntersects,
  rectWidth,
} from '../misc/geometry';
import {
  hitTestGraphLinks,
  hitTestGraphNodes,
  renderGraph,
} from '../visual/visual';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';
import { hitTestExpander, hitTestNode } from '../visual/node';
import { hitTestHandles, renderHandles } from '../visual/handles';
import { isRightEditorPaneOpen$ } from 'appLayout/isRightEditorPaneOpen$';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import { showRightPane } from 'appContainer/actions';
import { LayoutType, applyLayout, saveLayout } from '../layout/layout';
import {
  editCanDeleteCol,
  editCanDeleteRow,
  editDeleteCol,
  editDeleteRow,
  editInsertCol,
  editInsertRow,
  editMove,
  editResize,
} from '../layout/edit';
import {
  getColIndex,
  getDragOffset,
  getResizedIndices,
  getRowIndex,
} from './util';
import { dragOperationIsResize } from '../util';
import { getBlockGrid } from '../layout/blockGrid';
import {
  getColPositions,
  getGridPosition,
  getRowPositions,
} from '../layout/util';
import {
  BLOCKS_VIEW_BADDROP_FILL,
  BLOCKS_VIEW_BADDROP_STROKE,
  BLOCKS_VIEW_BADRESIZE_FILL,
  BLOCKS_VIEW_FIT_PADDING,
  BLOCKS_VIEW_GOODDROP_FILL,
  BLOCKS_VIEW_GOODDROP_STROKE,
  BLOCKS_VIEW_NODE_BORDERRADIUS,
  lineTypeDotted,
  lineTypeSolid,
} from '../consts';
import dragImageGetter from './dragImageGetter';
import {
  linkContextMenuOptions,
  nodeContextMenuOptions,
  rootContextMenuOptions,
} from './contextMenuOptions';
import {
  register as registerCanvasHitTest,
  unregister as unregisterCanvasHitTest,
  type ClientModelHitTest,
} from 'tabview/relationsD3View/hierarchical/canvasHitTestRegistry';
import { getWorkspaceMenu } from 'contextMenus/workspaceMenu';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';
import {
  layoutSettingViewActionButton,
  layoutSettingViewActionOption,
} from './viewActions';
import {
  getExpandOrCollapseGroupMenuItem,
  getZoomToFitContextMenuItem,
} from 'contextMenus/utils';
import {
  getTrackingFunction,
  TrackedContextMenus,
} from 'contextMenus/tracking';
import transformComponentMenu from './transformComponentMenu';

const cursor = (dragOperation: DragOperation) => {
  switch (dragOperation) {
    case DragOperation.None:
      return 'default';
    case DragOperation.Pan:
      return 'grabbing';
    case DragOperation.AreaSelect:
      return 'default';
    case DragOperation.Expand:
      return 'default';
    case DragOperation.Left:
      return 'ew-resize';
    case DragOperation.LeftTop:
      return 'nwse-resize';
    case DragOperation.Top:
      return 'ns-resize';
    case DragOperation.RightTop:
      return 'nesw-resize';
    case DragOperation.Right:
      return 'ew-resize';
    case DragOperation.RightBottom:
      return 'nwse-resize';
    case DragOperation.Bottom:
      return 'ns-resize';
    case DragOperation.LeftBottom:
      return 'nesw-resize';
    case DragOperation.Move:
      return 'all-scroll';
  }

  return 'default';
};

export const zoomToFitOnClick =
  (controller: Controller, graph: BlocksViewGraph) => () => {
    controller.fit(
      controller.selection.empty()
        ? graph.root.bounds!
        : controller.selection.bounds()
    );
  };

const zoomToFitConfig = (controller: Controller, graph: BlocksViewGraph) =>
  layoutSettingViewActionButton({
    id: 'id_zoom_to_fit',
    label: 'Fit',
    onClick: zoomToFitOnClick(controller, graph),
    iconName: IconName.ZOOM_TO_FIT,
    viewActionName: 'zoomToFit',
  });

/** sets the visual drag state for the node and all descendants. */
const setVisualDragState = (node: BlocksViewNode, isDragging: boolean) => {
  node.visualState = isDragging
    ? node.visualState | BlocksViewVisualState.Dragging
    : node.visualState & ~BlocksViewVisualState.Dragging;
  node.children?.forEach(child => setVisualDragState(child, isDragging));
};

export const createEditInteractions = (
  controller: Controller,
  graph: BlocksViewGraph
): Interactions => {
  let dragOperation = DragOperation.None;
  let isDropValid: boolean; // is it possible to drop at the moment
  let pointerDevice: Vector = [NaN, NaN];
  let pointerWorld: Vector = [NaN, NaN];
  let pointerDownWorld: Vector = [NaN, NaN]; // world position of mouse down at drag start
  let pointerDownDevice: Vector = [NaN, NaN]; // device position of mouse down at drag start
  let pointerId: number = 0;
  let pointerLink: BlocksViewLink | null = null;
  let pointerNode: BlocksViewNode | null = null;
  let dragNode: BlocksViewNode | null = null;

  const dragImages = new Map<BlocksViewNode, ImageBitmap>();
  const canvas = controller.canvas;
  const projection = controller.projection;
  const selection = controller.selection;
  const ctx = canvas.getContext('2d', { alpha: false })!;

  const hitTest: ClientModelHitTest = ({ clientX, clientY }) => {
    if (!graph.root) {
      return null;
    }

    const { left: canvasLeft, top: canvasTop } = canvas.getBoundingClientRect();
    const viewportX = clientX - canvasLeft;
    const viewportY = clientY - canvasTop;

    return (
      hitTestGraphNodes(graph.root, projection.toWorld([viewportX, viewportY]))
        ?.modelId ?? null
    );
  };

  const attach = () => {
    canvas.addEventListener('pointerdown', onPointerDown);
    canvas.addEventListener('pointermove', onPointerMove);
    canvas.addEventListener('pointerup', onPointerUp);
    canvas.addEventListener('pointerleave', onPointerLeave);
    canvas.addEventListener('lostpointercapture', onLostPointerCapture);
    canvas.addEventListener('wheel', onWheel);
    canvas.addEventListener('keydown', onKeyDown);
    canvas.addEventListener('dblclick', onDoubleClick);
    canvas.addEventListener('contextmenu', onContextMenu);
    canvas.addEventListener('focusin', onFocus);
    canvas.addEventListener('focusout', onFocus);
    canvas.addEventListener('blur', onFocus);
    registerCanvasHitTest(controller.viewInstanceId, hitTest);
  };

  const detach = () => {
    canvas.removeEventListener('pointerdown', onPointerDown);
    canvas.removeEventListener('pointermove', onPointerMove);
    canvas.removeEventListener('pointerup', onPointerUp);
    canvas.removeEventListener('pointerleave', onPointerLeave);
    canvas.removeEventListener('lostpointercapture', onLostPointerCapture);
    canvas.removeEventListener('wheel', onWheel);
    canvas.removeEventListener('keydown', onKeyDown);
    canvas.removeEventListener('dblclick', onDoubleClick);
    canvas.removeEventListener('contextmenu', onContextMenu);
    canvas.removeEventListener('focusin', onFocus);
    canvas.removeEventListener('focusout', onFocus);
    unregisterCanvasHitTest(controller.viewInstanceId);
  };

  function* actionGroups() {
    if (selection.empty()) {
      yield graph.root;
    } else {
      const set = new Set<BlocksViewNode>();

      for (const node of selection.nodes()) {
        set.add(isGroup(node) ? node : node.parent!);
      }

      yield* set.values();
    }
  }

  const testDropValid = () => {
    let ret = true;

    if (dragOperationIsResize(dragOperation) && dragNode) {
      const delta: Vector = [
        pointerWorld[0] - pointerDownWorld[0],
        pointerWorld[1] - pointerDownWorld[1],
      ];

      const grid = selection.group();
      const blockGrid = grid ? getBlockGrid(grid) : null;

      if (grid && blockGrid) {
        const indices = getResizedIndices(dragNode, dragOperation, delta);

        for (let c = indices[0]; c < indices[2]; ++c) {
          for (let r = indices[1]; r < indices[3]; ++r) {
            const n = blockGrid.get(c, r);

            if (n && n !== dragNode) {
              ret = false;
            }
          }
        }
      }
    }

    if (dragOperation === DragOperation.Move) {
      const delta: Vector = [
        pointerWorld[0] - pointerDownWorld[0],
        pointerWorld[1] - pointerDownWorld[1],
      ];

      const grid = selection.group();
      const blockGrid = grid ? getBlockGrid(grid) : null;

      if (grid && blockGrid) {
        const offset = getDragOffset(dragNode!, delta);

        selection.forEach(node => {
          const col = node.col + offset[0];
          const row = node.row + offset[1];

          for (let c = 0; c < node.colSpan && ret; ++c) {
            for (let r = 0; r < node.rowSpan && ret; ++r) {
              const n = blockGrid.get(col + c, row + r);

              if (n && !selection.has(n)) {
                ret = false;
              }
            }
          }
        });
      }
    }

    return ret;
  };

  const onSelectionChanged = () => {
    if (isRightEditorPaneOpen$.state) {
      if (selection.empty() && pointerLink) {
        dispatchAction(
          showRightPane({
            type: GetContentOptionsType.REFERENCE_PROPERTIES,
            referenceIds: [pointerLink.modelId],
          })
        );
        return;
      }

      if (!selection.empty()) {
        const modelIds: string[] = [];
        selection.nodes().forEach(node => modelIds.push(node.modelId!));
        dispatchAction(
          showRightPane({
            type: GetContentOptionsType.COMPONENT_PROPERTIES,
            componentIds: modelIds,
          })
        );
        return;
      }
    }
  };

  const setMouseOverLink = (link: BlocksViewLink | null) => {
    if (link !== pointerLink) {
      if (pointerLink) {
        pointerLink.visualState =
          pointerLink.visualState & ~BlocksViewVisualState.Hover;
      }

      pointerLink = link;

      if (pointerLink) {
        pointerLink.visualState =
          pointerLink.visualState | BlocksViewVisualState.Hover;
      }
    }
  };

  const setMouseOverNode = (node: BlocksViewNode | null) => {
    if (node !== pointerNode) {
      if (pointerNode) {
        pointerNode.visualState =
          pointerNode.visualState & ~BlocksViewVisualState.Hover;
      }

      pointerNode = node;

      if (pointerNode) {
        pointerNode.visualState =
          pointerNode.visualState | BlocksViewVisualState.Hover;
      }
    }
  };

  const onNodeEnter = (node: BlocksViewNode) => {
    if (node !== graph.root) {
      setMouseOverLink(null);
      setMouseOverNode(node);

      controller.showNodeTooltip(pointerNode!, pointerWorld);
    }
  };

  const onNodeLeave = () => {
    setMouseOverNode(null);

    graph.edges.forEach(link => {
      link.visualState = link.visualState & ~BlocksViewVisualState.Highlight;
    });

    controller.cancelTooltip();
  };

  const updatePointerWorld = () =>
    (pointerWorld = controller.projection.toWorld(pointerDevice));

  const onNodeClick = (node: BlocksViewNode) => {
    if (isGroup(node) && hitTestExpander(node, pointerWorld)) {
      controller.setOpen(node, !node.open);
      updatePointerWorld(); // the controller adjusts the window after an expander click opens or closes a node. update pointerWorld now so it's current if the user immediately clicks the mouse again.
      controller.renderCanvas();
    }
  };

  const onNodeKeyDown = (node: BlocksViewNode, e: KeyboardEvent) => {
    if (e.key === ' ' && isGroup(node)) {
      controller.setOpen(node, !node.open);

      e.stopPropagation();
      controller.renderCanvas();
      return true;
    }

    return false;
  };

  const onLinkEnter = (link: BlocksViewLink) => {
    setMouseOverLink(link);
    setMouseOverNode(null);
    if (!link.labels) {
      return;
    }

    let tooltip = '';

    for (const label of link.labels) {
      tooltip = `${tooltip} ${label}\n`;
    }

    controller.showLinkTooltip(pointerLink!, pointerWorld);
  };

  const onLinkLeave = () => {
    setMouseOverLink(null);
    controller.cancelTooltip();
  };

  const onDragBegin = () => {
    const selection = controller.selection;

    isDropValid = true;

    if (dragOperation === DragOperation.None && !selection.empty()) {
      selection.forEach(node => {
        if (dragOperation === DragOperation.None) {
          dragOperation = hitTestHandles(node.cell!, pointerWorld, ctx);

          if (dragOperation !== DragOperation.None) {
            isDropValid = testDropValid();
            dragNode = node;
          }
        }
      });
    }

    if (
      dragOperation === DragOperation.None &&
      pointerNode &&
      selection.has(pointerNode)
    ) {
      dragOperation = DragOperation.Move;
      dragNode = pointerNode;
      setVisualDragState(dragNode, true);
      selection.forEach(node => setVisualDragState(node, true));
    }

    if (dragOperation === DragOperation.None) {
      dragOperation = DragOperation.Pan;
    }
  };

  const onDrag = (_e: PointerEvent) => {
    if (dragOperation === DragOperation.Pan) {
      controller.projection.adjustToPoint(pointerDownWorld, pointerDevice);
      updatePointerWorld();
      controller.renderCanvas();
    }

    if (dragOperation === DragOperation.AreaSelect) {
      controller.renderCanvas();
    }

    if (dragOperation === DragOperation.Move) {
      isDropValid = testDropValid();
      controller.renderCanvas();
    }

    if (dragOperationIsResize(dragOperation)) {
      isDropValid = testDropValid();
      controller.renderCanvas();
    }
  };
  const clearDragVisualState = () => {
    selection.forEach(node => setVisualDragState(node, false));
    if (pointerNode) {
      setVisualDragState(pointerNode, false);
    }
  };
  const onDragEnd = () => {
    if (!isDropValid) {
      onDragCancel();
      return;
    }

    dragImages.clear();

    if (dragOperation === DragOperation.AreaSelect) {
      const dragRect = rectEnclose(pointerDownWorld, pointerWorld);

      if (selection.empty()) {
        // get all the nodes in the dragRect
        // the first one defines the group
        // TODO AREA SELECT from empty selection
      } else {
        const group = selection.group()!;

        group.children?.forEach(node => {
          if (rectIntersects(node.bounds, dragRect)) {
            if (selection.has(node)) {
              selection.remove(node);
            } else {
              selection.add(node);
            }
          }
        });

        onSelectionChanged();
      }

      controller.renderCanvas();
    }

    if (dragOperation === DragOperation.Move && !selection.empty()) {
      const delta: Vector = [
        pointerWorld[0] - pointerDownWorld[0],
        pointerWorld[1] - pointerDownWorld[1],
      ];

      const dragOffset = getDragOffset(dragNode!, delta);

      if (dragOffset[0] !== 0 || dragOffset[1] !== 0) {
        // move the cells to where they appear on the screen so that they animate from there

        selection.nodes().forEach(node => {
          const cell: Rectangle = [
            node.cell![0] + delta[0],
            node.cell![1] + delta[1],
            node.cell![2] + delta[0],
            node.cell![3] + delta[1],
          ];

          node.cell = cell;
        });

        // now move the nodes

        editMove(selection.group()!, selection.nodes(), dragOffset);
        applyLayout(graph);
        saveLayout(graph.root);
      }
      clearDragVisualState();
    }

    if (dragOperationIsResize(dragOperation) && dragNode) {
      const delta: Vector = [
        pointerWorld[0] - pointerDownWorld[0],
        pointerWorld[1] - pointerDownWorld[1],
      ];

      const irc = getResizedIndices(dragNode, dragOperation, delta);
      const col = irc[0];
      const row = irc[1];
      const colSpan = irc[2] - irc[0];
      const rowSpan = irc[3] - irc[1];

      if (
        col !== dragNode.col ||
        row !== dragNode.row ||
        colSpan !== dragNode.colSpan ||
        rowSpan !== dragNode.rowSpan
      ) {
        editResize(dragNode, irc[0], irc[2] - irc[0], irc[1], irc[3] - irc[1]);

        applyLayout(graph);
        saveLayout(graph.root);
        controller.renderCanvas();
      }
    }
  };

  const onDragCancel = () => {
    dragImages.clear();

    dragOperation = DragOperation.None;
    clearDragVisualState();
    controller.renderCanvas();
  };

  // this is all pretty much copy pasted from the original. to be redone

  const renderCanvas = () => {
    const selection = controller.selection;

    ctx.setTransform(controller.projection.matrix());

    const done = renderGraph(
      graph,
      controller.projection.window(),
      'normal',
      selection.group(),
      ctx
    );

    if (
      !selection.empty() &&
      selection.group()!.open &&
      isAscendancyOpen(selection.group()!)
    ) {
      if (
        dragOperation === DragOperation.None ||
        dragOperation === DragOperation.AreaSelect ||
        dragOperation === DragOperation.Pan
      ) {
        selection.forEach(node => {
          renderHandles(node.cell!, ctx);
        });
      }

      if (dragOperation === DragOperation.Move) {
        const delta: Vector = [
          pointerWorld[0] - pointerDownWorld[0],
          pointerWorld[1] - pointerDownWorld[1],
        ];
        const grid = selection.group()!;
        const offset = getDragOffset(dragNode!, delta);
        const colPositions = getColPositions(grid);
        const rowPositions = getRowPositions(grid);

        /* draw the selection drop ghosts */

        selection.forEach(node => {
          const col = node.col + offset[0];
          const row = node.row + offset[1];

          const cell: Rectangle = [
            colPositions[col],
            rowPositions[row],
            colPositions[col + node.colSpan],
            rowPositions[row + node.rowSpan],
          ];

          if (isDropValid) {
            const getDragImage = dragImageGetter(dragImages);
            const image = getDragImage(node);
            const width = rectWidth(node.cell!);
            const height = rectHeight(node.cell!);

            ctx.globalAlpha = 0.8;
            ctx.drawImage(
              image,
              (cell[0] + cell[2] - width) / 2,
              (cell[1] + cell[3] - height) / 2,
              width,
              height
            );
            ctx.globalAlpha = 1;
          } else {
            ctx.setLineDash(lineTypeDotted);
          }
          ctx.strokeStyle = isDropValid
            ? BLOCKS_VIEW_GOODDROP_STROKE
            : BLOCKS_VIEW_BADDROP_STROKE;
          ctx.fillStyle = isDropValid
            ? BLOCKS_VIEW_GOODDROP_FILL
            : BLOCKS_VIEW_BADDROP_FILL;
          ctx.lineWidth = 1 / ctx.getTransform().a;

          ctx.beginPath();
          ctx.roundRect(
            cell[0],
            cell[1],
            rectWidth(cell),
            rectHeight(cell),
            BLOCKS_VIEW_NODE_BORDERRADIUS
          );
          ctx.fill();
          ctx.stroke();
          ctx.setLineDash(lineTypeSolid);
        });

        /* draw the selection-in-motion */

        selection.forEach(node => {
          const getDragImage = dragImageGetter(dragImages);
          const image = getDragImage(node);
          const cell: Rectangle = [
            node.cell![0] + delta[0],
            node.cell![1] + delta[1],
            node.cell![2] + delta[0],
            node.cell![3] + delta[1],
          ];

          if (image !== null) {
            ctx.globalAlpha = 0.32;
            ctx.drawImage(
              image,
              cell[0],
              cell[1],
              rectWidth(cell),
              rectHeight(cell)
            );
            ctx.globalAlpha = 1.0;
          }
        });

        /* draw the selection handles */
        selection.forEach(node => {
          const rc: Rectangle = [
            node.cell![0] + delta[0],
            node.cell![1] + delta[1],
            node.cell![2] + delta[0],
            node.cell![3] + delta[1],
          ];

          renderHandles(rc, ctx);
        });
      }

      if (dragNode && dragOperationIsResize(dragOperation)) {
        const delta: Vector = [
          pointerWorld[0] - pointerDownWorld[0],
          pointerWorld[1] - pointerDownWorld[1],
        ];
        const grid = dragNode.parent!;

        /* node drop ghost */
        const i = getResizedIndices(dragNode, dragOperation, delta); // drop rect in grid indices
        const lt = getGridPosition(grid, [i[0], i[1]]);
        const rb = getGridPosition(grid, [i[2], i[3]]);
        const cell: Rectangle = [...lt, ...rb]; // drop cell in world coords
        const getDragImage = dragImageGetter(dragImages);
        const image = getDragImage(dragNode);

        ctx.globalAlpha = 0.5;
        ctx.drawImage(
          image,
          cell[0],
          cell[1],
          rectWidth(cell),
          rectHeight(cell)
        );
        ctx.globalAlpha = 1.0;

        if (!isDropValid) {
          ctx.setLineDash(lineTypeDotted);
          ctx.lineWidth = 2;
          ctx.strokeStyle = BLOCKS_VIEW_BADDROP_STROKE;
          ctx.fillStyle = BLOCKS_VIEW_BADRESIZE_FILL;
          ctx.fillRect(cell[0], cell[1], rectWidth(cell), rectHeight(cell));
          ctx.strokeRect(cell[0], cell[1], rectWidth(cell), rectHeight(cell));
          ctx.setLineDash(lineTypeSolid);
          ctx.lineWidth = 1;
        }

        // handles of resizeNode
        const rc: Rectangle = [...dragNode.cell!];
        rc[0] += dragOperation & DragOperation.Left ? delta[0] : 0;
        rc[1] += dragOperation & DragOperation.Top ? delta[1] : 0;
        rc[2] += dragOperation & DragOperation.Right ? delta[0] : 0;
        rc[3] += dragOperation & DragOperation.Bottom ? delta[1] : 0;
        renderHandles(rc, ctx);
      }
    }

    if (dragOperation === DragOperation.AreaSelect) {
      const mouseRect = rectEnclose(pointerDownWorld, pointerWorld);

      ctx.strokeStyle = 'black';
      ctx.lineWidth = 1.0 / ctx.getTransform().a;
      ctx.strokeRect(
        mouseRect[0],
        mouseRect[1],
        mouseRect[2] - mouseRect[0],
        mouseRect[3] - mouseRect[1]
      );
    }

    return done;
  };

  const onPointerDown = (e: PointerEvent) => {
    const selection = controller.selection;
    controller.cancelTooltip();

    if (e.button === 0) {
      /* left mouse button */

      pointerDownDevice = [e.offsetX, e.offsetY];
      pointerDownWorld = controller.projection.toWorld(pointerDownDevice);
      dragOperation = DragOperation.None; // no idea initially

      controller.canvas.setPointerCapture(e.pointerId); // grab the pointer
      pointerId = e.pointerId;

      // decide if mouse down should modifiy the selection

      let modifySelection = true;

      if (modifySelection && pointerNode) {
        /* don't modify the selection if I click on the expander */
        modifySelection = !hitTestExpander(pointerNode, pointerDownWorld);
      }

      if (modifySelection && !selection.empty()) {
        /* don't modify the selection if I click on a handle */
        selection.forEach(node => {
          modifySelection =
            modifySelection &&
            hitTestHandles(node.cell!, pointerDownWorld, ctx) ===
              DragOperation.None;
        });
      }

      if (modifySelection && !e.shiftKey && !e.altKey) {
        /* single select */
        if (!pointerNode || !selection.has(pointerNode)) {
          selection.clear();
        }

        if (pointerNode) {
          selection.add(pointerNode);
        }

        onSelectionChanged();
      }

      if (!e.shiftKey && e.altKey) {
        /* this is going to be an area select */
        // should be calling onDragBegin()
        dragOperation = DragOperation.AreaSelect;
        controller.canvas.style.cursor = cursor(dragOperation);
      }

      if (modifySelection && e.shiftKey && !e.altKey) {
        /* extended select */
        if (pointerNode) {
          if (selection.has(pointerNode)) {
            selection.remove(pointerNode);
          } else if (
            selection.empty() ||
            selection.group() === pointerNode.parent
          ) {
            selection.add(pointerNode);
          }

          onSelectionChanged();
        }
      }

      controller.renderCanvas();
    }
  };

  const onPointerMove = (e: PointerEvent) => {
    pointerDevice = [e.offsetX, e.offsetY];
    updatePointerWorld();

    if (canvas.hasPointerCapture(e.pointerId)) {
      if (
        dragOperation === DragOperation.None &&
        Math.hypot(
          pointerDownDevice[0] - e.clientX,
          pointerDownDevice[0] - e.clientY
        ) > 4
      ) {
        onDragBegin();
        canvas.style.cursor = cursor(dragOperation);
      }

      if (dragOperation !== DragOperation.None) {
        onDrag(e);
      }
    } else {
      const link = hitTestGraphLinks(graph, pointerWorld, ctx);
      let node = !link ? hitTestGraphNodes(graph.root, pointerWorld) : null;

      node = node !== graph.root ? node : null;

      if (link !== pointerLink) {
        if (pointerLink) {
          onLinkLeave();
        }

        if (link) {
          onLinkEnter(link);
        }
      }

      if (node !== pointerNode) {
        if (pointerNode) {
          onNodeLeave();
        }

        if (node) {
          onNodeEnter(node);
        }
      }

      /** @returns the cursor corresponding roughly to what would happen if a drag start happened now */
      const previewCursor = () => {
        if (pointerNode && hitTestExpander(pointerNode, pointerWorld)) {
          // mouse is over an expander
          return 'pointer';
        }

        let previewOperation = DragOperation.None;

        if (previewOperation === DragOperation.None && !selection.empty()) {
          selection.forEach(node => {
            if (previewOperation === DragOperation.None) {
              previewOperation = hitTestHandles(node.cell!, pointerWorld, ctx);
            }
          });
        }

        if (
          previewOperation === DragOperation.None &&
          pointerNode &&
          !selection.has(pointerNode)
        ) {
          // mouse if over an unselected node
          return 'default';
        }

        if (previewOperation === DragOperation.None && !selection.empty()) {
          selection.forEach(node => {
            if (previewOperation === DragOperation.None) {
              previewOperation = hitTestNode(node, pointerWorld)
                ? DragOperation.Move
                : DragOperation.None;
            }
          });
        }

        return cursor(previewOperation);
      };

      // need to know what a mouse down would do
      // could be a selectionresize (need to know)
      // could be selectionmove (need to know)
      // could be expand/collapse (need to know)
      // could be area select (probably use the default cursor)
      // could be pan (probably use the default cursor)

      canvas.style.cursor = previewCursor();
      controller.renderCanvas(); // only if mouseOverLink or mouseOverNode has changed
    }
  };

  const onPointerUp = (e: PointerEvent) => {
    if (canvas.hasPointerCapture(e.pointerId)) {
      if (dragOperation === DragOperation.None) {
        if (pointerNode) {
          onNodeClick(pointerNode);
        }
      } else {
        onDragEnd();
        dragOperation = DragOperation.None;
      }

      canvas.releasePointerCapture(e.pointerId);
      controller.renderCanvas();
    }
  };

  // this is generic to all controllers
  const onWheel = (e: WheelEvent) => {
    const deltaY = clamp(e.deltaY, -800, 800) / 1000;

    e.stopPropagation();
    controller.cancelTooltip();
    controller.zoomTo(pointerWorld, 1 - deltaY);
  };

  // this will become generic to all controllers
  const onPointerLeave = (e: PointerEvent) => {
    if (!e.isPrimary) {
      return;
    }

    if (pointerLink) {
      onLinkLeave();
    }

    if (pointerNode) {
      onNodeLeave();
    }
  };

  // this will become generic to all controllers
  const onLostPointerCapture = (e: PointerEvent) => {
    if (e.pointerId === pointerId && dragOperation !== DragOperation.None) {
      onDragCancel();
      pointerId = -1;
    }
  };

  // pretty sure this should be generic to all controllers
  const onKeyDown = (event: KeyboardEvent) => {
    const rc = projection.window();
    const speed = event.shiftKey ? 3 : 1;
    let handled = true;

    for (const node of selection.nodes()) {
      /* pass through to selection */
      if (onNodeKeyDown(node, event)) {
        return;
      }
    }

    switch (event.key) {
      case 'PageUp':
        controller.zoomTo(rectCenter(rc), 1.1 * speed);
        break;

      case 'PageDown':
        controller.zoomTo(rectCenter(rc), 0.9 / speed);
        break;

      case 'ArrowLeft': // possibly an action of some kind
        projection.translateBy([speed * 0.05 * rectWidth(rc), 0]);
        break;

      case 'ArrowUp': // possibly an action of some kind
        projection.translateBy([0, speed * 0.05 * rectHeight(rc)]);
        break;

      case 'ArrowRight': // possibly an action of some kind
        projection.translateBy([-speed * 0.05 * rectWidth(rc), 0]);
        break;

      case 'ArrowDown': // possibly an action of some kind
        projection.translateBy([0, -speed * 0.05 * rectHeight(rc)]);
        break;

      case 'Enter':
        if (event.shiftKey && !selection.empty()) {
          projection.adjustToRect(selection.bounds(), BLOCKS_VIEW_FIT_PADDING);
        } else {
          projection.adjustToRect(graph.root.bounds!, BLOCKS_VIEW_FIT_PADDING);
        }
        break;

      case 'Escape':
        if (pointerId >= 0 && canvas.hasPointerCapture(pointerId)) {
          canvas.releasePointerCapture(pointerId);
          event.stopPropagation();
        }
        break;

      case 'Tab':
        if (!selection.empty() && selection.group()!.children!.length > 1) {
          const group = selection.group();
          const sel = selection.nodes().values().next().value;
          const index =
            group && sel
              ? group?.children?.findIndex(a => a === sel)
              : undefined;

          if (group && group.children && sel && index !== undefined) {
            const next = event.shiftKey ? index - 1 : index + 1;
            const length = group?.children?.length;

            selection.clear();
            selection.add(
              group?.children![
                next < 0 ? length - 1 : next >= length ? 0 : next
              ]
            );

            event.stopPropagation();
            event.preventDefault();

            onSelectionChanged();
            controller.renderCanvas();
          }
        }
        break;
      case 'f':
        if (event.ctrlKey) {
          controller.cancelTooltip();
          alert('you want to find a node');
          event.stopPropagation();
        }
        break;

      default:
        handled = false;
        break;
    }

    if (handled) {
      // mousePos = projection.toWorld(pointerDevice); // recalculate the world position just in case the projection got changed
      controller.cancelTooltip();
      controller.renderCanvas(); // some of the events will already have done this, but rendering twice is a free operation
      event.stopPropagation();
    }
  };

  // this should be generic to all controllers
  const onDoubleClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (pointerNode && pointerNode.modelId) {
      dispatchAction(openDetailsDrawer([pointerNode.modelId!]));
    }
  };

  // this is generic to all controllers, but relies on controller contextMenuOptions
  const onContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const options = contextMenuOptions();

    if (dragOperation === DragOperation.None && options.length !== 0) {
      controller.cancelTooltip();
      const selectedNode = !pointerLink && pointerNode;
      if (selectedNode && !selection.has(selectedNode)) {
        selection.clear();
        selection.add(selectedNode);
      }
      dispatchAction(
        setContextMenuState({
          items: options,
          position: { left: e.clientX, top: e.clientY },
        })
      );
    }
  };

  // this is generic to all controllers
  const onFocus = (_e: FocusEvent) => {
    // view has gained or lost focus
    // this should be visible on the screen in some way
    controller.renderCanvas();
  };

  const contextMenuOptions = (): DropdownItem[] => {
    if (pointerLink) {
      const options = linkContextMenuOptions(controller, pointerLink);

      return options;
    }

    if (pointerNode) {
      const clickedNode = pointerNode; // pointerNode is a let variable that might change by the time a context menu option is clicked.
      const options: DropdownItem[] = [];
      const pointerNodeIsGroup = isGroup(pointerNode);
      const group = pointerNodeIsGroup ? pointerNode : pointerNode.parent!;
      const openGroup = group.open ? group : group.parent!;
      const { canvas } = controller;
      const trackContextMenu = getTrackingFunction(
        canvas,
        TrackedContextMenus.NON_COMPONENT_GROUP_CONTEXT_MENU
      );

      options.push(...bogusNodeMenuOptions(openGroup));
      options.push({ type: DropdownOptionType.DIVIDER, label: null });

      options.push(
        getZoomToFitContextMenuItem(
          () =>
            controller.fit(
              selection.empty() ? clickedNode.bounds : selection.bounds()
            ),
          trackContextMenu
        )
      );
      if (pointerNodeIsGroup) {
        const { open, modelId } = group;
        options.push(
          getExpandOrCollapseGroupMenuItem(
            open,
            () => controller.setOpen(group, !open),
            trackContextMenu
          )
        );
        options.push({ type: DropdownOptionType.DIVIDER });
        options.push({
          type: DropdownOptionType.OPTION,
          label: 'Rotate',
          onClick: () => {
            trackContextMenu('rotate');
            controller.rotate([clickedNode]);
          },
          iconName: IconName.ROTATE,
        });
        options.push({
          type: DropdownOptionType.OPTION,
          label: 'Flip Horizontally',
          onClick: () => {
            trackContextMenu('flipHorizontally');
            controller.flipHorizontal([clickedNode]);
          },
          iconName: IconName.FLIP_HORIZONTALLY,
        });
        options.push({
          type: DropdownOptionType.OPTION,
          label: 'Flip Vertically',
          onClick: () => {
            trackContextMenu('flipVertically');
            controller.flipVertical([clickedNode]);
          },
          iconName: IconName.FLIP_VERTICALLY,
        });
        const isWorkspace = modelId && workspaceInterface.isWorkspace(modelId);
        if (isWorkspace) {
          const workspaceMenu = getWorkspaceMenu({
            event: null,
            target: canvas,
            x: 0,
            y: 0,
            scenarioId: activeScenarioOperations.getActiveScenarioId(
              getActiveScenarioState()
            ),
            workspaceIds: [modelId],
            isViewpointMode: true,
          });
          if (workspaceMenu?.length) {
            options.push({ type: DropdownOptionType.DIVIDER, label: null });
            options.push(...workspaceMenu);
          }
        }
      }
      const nodeContextMenu = nodeContextMenuOptions(
        controller,
        pointerNode,
        selection.nodes()
      ).reduce(transformComponentMenu, []);
      if (nodeContextMenu.length) {
        options.push({ type: DropdownOptionType.DIVIDER });
        options.push(...nodeContextMenu);
      }

      return options;
    }

    if (!pointerLink && !pointerNode) {
      const options = [];

      options.push(...bogusNodeMenuOptions(graph.root));
      options.push({ type: DropdownOptionType.DIVIDER, label: null });
      options.push(...rootContextMenuOptions(controller!));

      return options;
    }

    return [];
  };

  const bogusNodeMenuOptions = (group: BlocksViewNode) => {
    const options = [];
    const col = group ? getColIndex(group, pointerWorld[0]) : undefined;
    const row = group ? getRowIndex(group, pointerWorld[1]) : undefined;

    // these are not implemented as view actions because they're about to be removed

    options.push({
      iconName: IconName.TABLE_ROWS,
      label: 'Insert row',
      type: DropdownOptionType.OPTION,
      isDisabled: !(row && row > 0 && row < group.rowSize!.length - 2),
      onClick: () => {
        editInsertRow(group, row!);
        applyLayout(graph);
        controller.renderCanvas();
      },
    });

    options.push({
      iconName: IconName.TABLE_ROWS,
      label: 'Delete row',
      type: DropdownOptionType.OPTION,
      onClick: () => {
        editDeleteRow(group, row!);
        applyLayout(graph);
        controller.renderCanvas();
      },
      isDisabled: !(row && editCanDeleteRow(group, row)),
    });

    options.push({
      iconName: IconName.TABLE_COLUMNS,
      label: 'Insert column',
      type: DropdownOptionType.OPTION,
      isDisabled: !(col && col > 0 && col < group.colSize!.length - 2),
      onClick: () => {
        editInsertCol(group, col!);
        applyLayout(graph);
        controller.renderCanvas();
      },
    });

    options.push({
      iconName: IconName.TABLE_COLUMNS,
      label: 'Delete column',
      type: DropdownOptionType.OPTION,
      onClick: () => {
        editDeleteCol(group, col!);
        applyLayout(graph);
        controller.renderCanvas();
      },
      isDisabled: !(col && editCanDeleteCol(group, col)),
    });

    return options;
  };

  const viewActionsConfigs = (): SettingsConfig[] => {
    const hasGroups = graph.root.children?.some(isGroup);
    const configs: SettingsConfig[] = [];
    /*
    configs.push({
      id: 'id_editmode',
      type: SettingsType.TOGGLE,
      label: 'Edit mode',
      onClick: () => controller.setInteractionMode(InteractionMode.View),
      iconName: IconName.EDIT,
      isDisabled: false,
    });
*/
    configs.push({
      id: 'id_undo',
      type: SettingsType.BUTTON,
      label: 'Undo',
      onClick: () => controller.undo(),
      iconName: IconName.UNDO,
      isDisabled: false,
    });

    configs.push({
      id: 'id_reset_layout',
      type: SettingsType.DROPDOWN,
      label: 'Apply',
      iconName: IconName.VIEW_COMPACT,
      options: [
        layoutSettingViewActionOption({
          label: 'Apply Smart Layout',
          onClick: () =>
            controller.resetLayout(actionGroups(), LayoutType.Smart),
          viewActionName: 'applyLayout',
          viewActionValue: LayoutType.Smart,
        }),
        layoutSettingViewActionOption({
          label: 'Apply Grid Layout',
          onClick: () =>
            controller.resetLayout(actionGroups(), LayoutType.Grid),
          viewActionName: 'applyLayout',
          viewActionValue: LayoutType.Grid,
        }),
      ],
    } satisfies DropdownConfig);

    configs.push({ id: 'id_divider1', type: SettingsType.DIVIDER });

    configs.push(
      layoutSettingViewActionButton({
        id: 'id_rotate',
        label: 'Rotate',
        onClick: () => {
          controller.rotate(actionGroups());
        },
        iconName: IconName.ROTATE,
        viewActionName: 'rotate',
      })
    );

    configs.push(
      layoutSettingViewActionButton({
        id: 'id_flip_h',
        label: 'Flip Horizontally',
        onClick: () => {
          controller.flipHorizontal(actionGroups());
        },
        iconName: IconName.FLIP_HORIZONTALLY,
        viewActionName: 'flipHorizontally',
      })
    );

    configs.push(
      layoutSettingViewActionButton({
        id: 'id_flip_v',
        label: 'Flip Vertically',
        onClick: () => {
          controller.flipVertical(actionGroups());
        },
        iconName: IconName.FLIP_VERTICALLY,
        viewActionName: 'flipVertically',
      })
    );

    configs.push({ id: 'id_divider2', type: SettingsType.DIVIDER });

    configs.push(
      layoutSettingViewActionButton({
        id: 'id_collapse_all',
        label: 'Collapse all',
        onClick: controller.collapseAll,
        iconName: IconName.COLLAPSE_ALL,
        isDisabled: !hasGroups,
        viewActionName: 'collapseAll',
      })
    );

    configs.push(
      layoutSettingViewActionButton({
        id: 'id_expand_all',
        label: 'Expand all',
        onClick: controller.expandAll,
        iconName: IconName.EXPAND_ALL,
        isDisabled: !hasGroups,
        viewActionName: 'expandAll',
      })
    );

    configs.push({ id: 'id_divider3', type: SettingsType.DIVIDER });

    configs.push(
      layoutSettingViewActionButton({
        id: 'id_zoom_in',
        label: 'Zoom in',
        onClick: controller.zoomIn,
        iconName: IconName.ZOOM_IN,
        isDisabled: false,
        viewActionName: 'zoomIn',
      })
    );

    configs.push(
      layoutSettingViewActionButton({
        id: 'id_zoom_out',
        label: 'Zoom out',
        onClick: controller.zoomOut,
        iconName: IconName.ZOOM_OUT,
        viewActionName: 'zoomOut',
      })
    );

    configs.push(zoomToFitConfig(controller, graph));

    return configs;
  };

  return {
    attach,
    detach,
    renderCanvas,
    contextMenuOptions,
    viewActionsConfigs,
  };
};
