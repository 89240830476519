import { ConditionalFormattingState } from '@ardoq/data-model';
import { formatOtherLabel, Rectangle } from '@ardoq/graph';
import { componentInterface } from '@ardoq/component-interface';
import { colors } from '@ardoq/design-tokens';
import { BlocksViewNode, BlocksViewVisualState } from '../types';
import { CreateRelationshipsNode } from 'tabview/relationshipDiagrams/types';
import { context$ } from 'streams/context/context$';
import { ROOT_LEAF_GROUP_ID } from 'tabview/relationsD3View/hierarchical/consts';
import { ROOT_NODE_ID } from '../consts';

const NODE_DEFAULTS = {
  col: NaN,
  colSpan: NaN,
  row: NaN,
  rowSpan: NaN,
  width: 60,
  height: 60,
  visualState: BlocksViewVisualState.None,
  conditionalFormattingState: ConditionalFormattingState.NORMAL,

  bounds: [0, 0, 1, 1] satisfies Rectangle,
  links: [],
  modelId: 'xyzzy',
  representationData: null,
  labels: [''],
  displayLabel: [],
  isContext: false,
  open: true,
  color: colors.transparent0,
  animation: null,
};

const createNode: CreateRelationshipsNode<BlocksViewNode> = (
  graphNode,
  parent,
  _children,
  representationData
) => {
  if (graphNode === 'rootLeafGroup') {
    return {
      ...NODE_DEFAULTS,
      children: null,
      parent: null,
      id: ROOT_LEAF_GROUP_ID,
    } satisfies BlocksViewNode;
  }
  if (graphNode === 'root') {
    return {
      ...NODE_DEFAULTS,
      id: ROOT_NODE_ID,
      parent: null,
      children: null,
    } satisfies BlocksViewNode;
  }
  const { id, modelId } = graphNode;

  const { mainLabel, subLabel, otherLabels } = graphNode.getItemLabels() ?? {};
  const labels: string[] = [];

  labels.push(mainLabel?.trim() ?? '');
  labels.push(subLabel?.trim() ?? '');

  for (const multilabel of otherLabels ?? []) {
    labels.push(formatOtherLabel(multilabel));
  }

  if (representationData) {
    representationData.shapeName = graphNode.getShape();
  }

  const node: BlocksViewNode = {
    ...NODE_DEFAULTS,
    id,
    modelId,
    labels,
    parent,
    children: null, // to be resolved later
    links: [], // to be resolved later
    representationData,
    isContext: Boolean(
      context$.state.componentId && modelId === context$.state.componentId
    ),
    color: componentInterface.getComponentColor(modelId) ?? colors.grey80,
    conditionalFormattingState:
      componentInterface.getConditionalFormattingState(modelId),
  };
  return node;
};

export default createNode;
